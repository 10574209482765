<template>
  <div class="wallet">
    <p class='walletHead'>Кошелек</p>
    <div class='walletBlock'>
      <p style='margin-right: 5px;'>Баланс</p> 
      <p style='color: #727272;'>{{ this.$store.getters.getUserData.balance.toLocaleString('fr-FR') }}₽</p>
    </div>
    <div class='depositButton' @click="deposit = true">
      Пополнить
    </div>
    <DepositComponent v-if="deposit" @onCloseDeposit="this.deposit=false" style="z-index: 10000;"/>
    <div class='withdrawButton' @click="withdraw = true">
      Вывести
    </div>
    <WithdrawComponent v-if="withdraw" @onCloseWithdraw="this.withdraw=false" style="z-index: 10000;"/>
  </div>
</template>

<script>
import DepositComponent from './DepositComponent.vue'
import WithdrawComponent from './WithdrawComponent.vue'

export default {
  name: 'ProfileComponent',
  data() {
    return {
      active: "profile",
      deposit: false,
      withdraw: false
    }
  },
  components: {
    DepositComponent,
    WithdrawComponent
  },
  methods: {
    
  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wallet {
  height:100vh;
  width: 20vw;
  background:black;
  /* position: absolute; */
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  /* left:6%; */
  text-align: left;
  padding: 20px;
}
.walletBlock {
  border-radius:10px;
  background-color: #141414;
  border: 1px solid #FFFFFF0D;
  padding: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  font-size: 18px;
}
.walletHead {
  font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;
}
.depositButton {
  background-color: white;
  color:black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  margin-bottom: 15px;
  cursor: pointer;
}
.withdrawButton {
  background-color: black;
  border:1px solid white;
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  cursor: pointer;
}
@media (max-width:968px) {
  .wallet {
    width:100%;
    background-image: url('../assets/backgroundMobile.png');
    background-position: 0 50vh;
    background-size: 200%;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    z-index: 8;
  }
  .walletBlock {
    margin-bottom: 3%;
    padding:17px
  }
  .walletHead {
    font-size: 24px;
  }
  
  .depositButton, .withdrawButton {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
