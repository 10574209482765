<template>
  <header class='header' id='header'>
    <div class='mainHead'>
      <div class='logo' @click='$router.push("#")'>
        <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13058 12.1035C6.16811 6.56257 10.737 2.26572 16.4119 1.63846V-1.52588e-05C9.8357 0.644106 4.54193 5.66033 3.4757 12.1035H5.13058ZM4.98808 15.9378H3.34961C4.02538 22.8367 9.51294 28.3243 16.4119 29V27.3615C10.4137 26.6985 5.65112 21.9359 4.98808 15.9378ZM20.2461 27.219V28.8739C26.6893 27.8076 31.7055 22.5139 32.3496 15.9378H30.7111C30.0839 21.6126 25.7871 26.1815 20.2461 27.219ZM30.5687 12.1035H32.2235C31.2096 5.97661 26.3731 1.14002 20.2461 0.126099V1.78097C25.4714 2.75942 29.5902 6.87829 30.5687 12.1035Z" fill="#0D1B2A"/>
        <path d="M1 13.947H10.8907C10.9673 13.947 11.0365 13.9014 11.0668 13.8311L13.4359 8.33146C13.5121 8.15447 13.7725 8.18797 13.8015 8.3785L15.3402 18.5012C15.373 18.7171 15.6834 18.719 15.7189 18.5036L17.8858 5.34417C17.9229 5.11916 18.2516 5.13538 18.2663 5.36295L19.4413 23.534C19.4547 23.741 19.7416 23.7823 19.8127 23.5874L23.2883 14.0729C23.316 13.9973 23.3839 13.947 23.4644 13.947C24.4101 13.947 30.3147 13.947 34.6932 13.947" stroke="#007BFF" stroke-width="1.34198" stroke-linecap="round"/>
        </svg>
        <p class='logoName'>Crypto Pulse</p>
      </div>
      <div class='abousUs'><a href='#secondBlock'>О компании</a></div>
      <div class='FAQ'><a href='#thirdBlock'>FAQ</a></div>
    </div>
    <div class="signUp authorization" v-if="this.$store.getters.getUserData" ><a href='/dashboard' style="color:white;">Личный кабинет</a></div>
    <div class='authorization' v-else>
      <a href='/auth'><div class='signIn'>Вход</div></a>
      <a href='/register' style='color:white;'><div class='signUp'>Регистрация</div></a>
    </div>
  </header>
  <header class='headerMobile' id='header'>
    <div class='mainHead'>
      <div class='logo' @click='$router.push("#")'>
        <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13058 12.1035C6.16811 6.56257 10.737 2.26572 16.4119 1.63846V-1.52588e-05C9.8357 0.644106 4.54193 5.66033 3.4757 12.1035H5.13058ZM4.98808 15.9378H3.34961C4.02538 22.8367 9.51294 28.3243 16.4119 29V27.3615C10.4137 26.6985 5.65112 21.9359 4.98808 15.9378ZM20.2461 27.219V28.8739C26.6893 27.8076 31.7055 22.5139 32.3496 15.9378H30.7111C30.0839 21.6126 25.7871 26.1815 20.2461 27.219ZM30.5687 12.1035H32.2235C31.2096 5.97661 26.3731 1.14002 20.2461 0.126099V1.78097C25.4714 2.75942 29.5902 6.87829 30.5687 12.1035Z" fill="#0D1B2A"/>
        <path d="M1 13.947H10.8907C10.9673 13.947 11.0365 13.9014 11.0668 13.8311L13.4359 8.33146C13.5121 8.15447 13.7725 8.18797 13.8015 8.3785L15.3402 18.5012C15.373 18.7171 15.6834 18.719 15.7189 18.5036L17.8858 5.34417C17.9229 5.11916 18.2516 5.13538 18.2663 5.36295L19.4413 23.534C19.4547 23.741 19.7416 23.7823 19.8127 23.5874L23.2883 14.0729C23.316 13.9973 23.3839 13.947 23.4644 13.947C24.4101 13.947 30.3147 13.947 34.6932 13.947" stroke="#007BFF" stroke-width="1.34198" stroke-linecap="round"/>
        </svg>
        <p class='logoName'>CryptoPulse</p>
      </div>
      <div style='justify-self: right;' @click='showHeadBar'>
        <img src='../assets/headOpen.svg' v-if='!headBar'/>
        <img src='../assets/headHide.svg' v-else/>
      </div>
    </div>
    
  </header>
  <Transition name='fade'>
    <div class='headMenuMobile' v-if='headBar'>
      <a href='#secondBlock'>О компании</a>
      <a href='#thirdBlock'>FAQ</a>
      <div class="signUp authorization" v-if="this.$store.getters.getUserData" ><a href='/dashboard' style="color:white;">Личный кабинет</a></div>
      <div class='authorization' v-else>
        <a href='/auth' class='signIn'><div >Вход</div></a>
        <a href='/register' style='color:white;' class='signUp'><div>Регистрация</div></a>
      </div>
    </div>
  </Transition>
  
  <div class='mainBlocks'>
    <div class='firstBlock'>
      <div class='firstBlockText'>
        <p style=''>Ощути пульс крипторынка <br>в реальном времени</p>
        <p style=''>Начинай торговать криптой уже сегодня</p>
      </div>
      <div class='firstBlockBottom'>
        <a :href='this.$store.getters.getUserData ? "/dashboard" : "/auth"'><div class='startTradeButton'>
          <p style='margin-right: 5px;'>Начать торговлю</p>
          <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 6.5C0.947715 6.5 0.5 6.94772 0.5 7.5C0.5 8.05228 0.947715 8.5 1.5 8.5L1.5 6.5ZM23.2071 8.20711C23.5976 7.81658 23.5976 7.18342 23.2071 6.7929L16.8431 0.428934C16.4526 0.0384091 15.8195 0.0384091 15.4289 0.428933C15.0384 0.819458 15.0384 1.45262 15.4289 1.84315L21.0858 7.5L15.4289 13.1569C15.0384 13.5474 15.0384 14.1805 15.4289 14.5711C15.8195 14.9616 16.4526 14.9616 16.8431 14.5711L23.2071 8.20711ZM1.5 8.5L22.5 8.5L22.5 6.5L1.5 6.5L1.5 8.5Z" fill="black"/>
          </svg>
        </div></a>
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9393 13.0608C11.5251 13.6465 12.4749 13.6465 13.0607 13.0608L22.6066 3.51482C23.1924 2.92903 23.1924 1.97928 22.6066 1.3935C22.0208 0.807712 21.0711 0.807712 20.4853 1.3935L12 9.87878L3.51472 1.3935C2.92893 0.807711 1.97919 0.807711 1.3934 1.3935C0.807612 1.97928 0.807612 2.92903 1.3934 3.51482L10.9393 13.0608ZM10.5 12L10.5 12.0001L13.5 12.0001L13.5 12L10.5 12Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class='secondBlock' id='secondBlock'>
      <div class='mobileBlock'>
        <div class='firstPossible'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14V6ZM6 8C6.55228 8 7 8.44772 7 9V14C7 14.5523 6.55229 15 6 15C5.44772 15 5 14.5523 5 14V9C5 8.44772 5.44772 8 6 8ZM14 10C14.5523 10 15 10.4477 15 11V14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14V11C13 10.4477 13.4477 10 14 10Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Аналитика на<br>кончиках пальцев</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; margin-top:15px;">Наши аналитические<br>инструменты просты в<br>использовании и подходят как<br>для новичков, так и для<br>профессионалов.<br>Визуализация рыночных<br>трендов, автоматизированные<br>отчеты и прогнозы помогают<br>пользователям принимать<br>обоснованные решения.</p>
        </div>
        <div class='secondPossible'>
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.82416 0.115428C8.2855 -0.0384761 7.7145 -0.038476 7.17584 0.115428L2.17584 1.544C0.887932 1.91197 0 3.08913 0 4.42857V9.80303C0 12.7096 1.40369 15.4372 3.76886 17.1266L6.25629 18.9034C7.29938 19.6484 8.70062 19.6484 9.74372 18.9034L12.2311 17.1266C14.5963 15.4372 16 12.7096 16 9.80303V4.42857C16 3.08913 15.1121 1.91197 13.8242 1.544L8.82416 0.115428ZM11.6839 6.19038C12.0869 6.56811 12.1073 7.20095 11.7295 7.60386L7.97954 11.6039C7.62129 11.986 7.02888 12.0269 6.62148 11.6977L4.37148 9.87953C3.94192 9.53241 3.87508 8.90278 4.22221 8.47322C4.56933 8.04366 5.19896 7.97682 5.62852 8.32395L7.15589 9.55819L10.2705 6.23598C10.6482 5.83307 11.281 5.81265 11.6839 6.19038Z" fill="black"/>
          </svg>

          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Безопасность на<br>первом месте</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; width:100%; margin-top:15px;">Безопасность средств и<br>данных пользователей — наш<br>главный приоритет. Мы<br>используем самые<br>современные методы защиты,<br>чтобы обеспечить<br>безопасность каждой<br>транзакции.</p>
        </div>
        <div class='thirdPossible'>
          <img src='../assets/iphone.png' style='width:35vw; margin-top:3vh;margin-bottom: -10vh;'/>
          <a href='/register' style='color:white;'><div class='registerButton'>
            Зарегистрироваться
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" style='margin-left:10px;'>
            <path d="M0.868089 15.2175C0.477564 15.608 0.477564 16.2411 0.868089 16.6317C1.25861 17.0222 1.89178 17.0222 2.2823 16.6317L0.868089 15.2175ZM17.4244 1.07532C17.4244 0.523033 16.9767 0.0753181 16.4244 0.0753179L7.42444 0.0753187C6.87215 0.0753183 6.42444 0.523034 6.42444 1.07532C6.42444 1.6276 6.87215 2.07532 7.42444 2.07532L15.4244 2.07532L15.4244 10.0753C15.4244 10.6276 15.8722 11.0753 16.4244 11.0753C16.9767 11.0753 17.4244 10.6276 17.4244 10.0753L17.4244 1.07532ZM2.2823 16.6317L17.1315 1.78243L15.7173 0.368212L0.868089 15.2175L2.2823 16.6317Z" fill="white"/>
            </svg>
          </div></a>
        </div>
        <div class='fourthPossible'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C7.23885 0 4.73748 1.12038 2.92893 2.92893C1.12038 4.73748 0 7.23885 0 10C0 12.7611 1.12038 15.2625 2.92893 17.0711C4.73748 18.8796 7.23885 20 10 20C12.7611 20 15.2625 18.8796 17.0711 17.0711C18.8796 15.2625 20 12.7611 20 10C20 7.23885 18.8796 4.73748 17.0711 2.92893C15.2625 1.12038 12.7611 0 10 0ZM10 5C10.5523 5 11 5.44772 11 6V9.58579L12.7071 11.2929C13.0976 11.6834 13.0976 12.3166 12.7071 12.7071C12.3166 13.0976 11.6834 13.0976 11.2929 12.7071L9.29289 10.7071C9.10536 10.5196 9 10.2652 9 10V6C9 5.44772 9.44771 5 10 5Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Ощути пульс рынка<br>
            в реальном времени</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; width:100%; margin-top:15px;">Платформа CryptoPulse<br>предоставляет пользователям<br>самые актуальные данные с<br>минимальными задержками.<br>Мгновенные оповещения,<br>кастомизированные под ваши<br>предпочтения, помогают<br>быстро реагировать на<br>волатильность рынка.</p>
        </div>
        <div class='fifthPossible'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM11.3449 5.46591C10.7947 4.35104 9.20494 4.35104 8.65472 5.46591L7.8663 7.06343L6.10332 7.31961C4.87299 7.49839 4.38173 9.01035 5.272 9.87815L6.5477 11.1216L6.24655 12.8775C6.03639 14.1029 7.32253 15.0373 8.42297 14.4588L9.99982 13.6298L11.5767 14.4588C12.6771 15.0373 13.9633 14.1029 13.7531 12.8775L13.4519 11.1216L14.7276 9.87815C15.6179 9.01035 15.1267 7.49839 13.8963 7.31961L12.1334 7.06343L11.3449 5.46591Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Интуитивный<br>интерфейс</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; width:100%; margin-top:15px;">Наша платформа создана для<br>удобства и эффективности.<br>Простота использования<br>сочетается с мощными<br>функциями, что позволяет<br>сосредоточиться на торговле,<br>не теряя времени на изучение<br>интерфейса.</p>
        </div>
      </div>
      
    </div>
    <div class='secondBlockMobile' id='secondBlock'>
      <img src='../assets/iphone 15.png' style='width: 300px; margin: 0 auto;'/>
      <div class='possibles'>
        <div class='PossibleMobile'>
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.82416 0.115428C8.2855 -0.0384761 7.7145 -0.038476 7.17584 0.115428L2.17584 1.544C0.887932 1.91197 0 3.08913 0 4.42857V9.80303C0 12.7096 1.40369 15.4372 3.76886 17.1266L6.25629 18.9034C7.29938 19.6484 8.70062 19.6484 9.74372 18.9034L12.2311 17.1266C14.5963 15.4372 16 12.7096 16 9.80303V4.42857C16 3.08913 15.1121 1.91197 13.8242 1.544L8.82416 0.115428ZM11.6839 6.19038C12.0869 6.56811 12.1073 7.20095 11.7295 7.60386L7.97954 11.6039C7.62129 11.986 7.02888 12.0269 6.62148 11.6977L4.37148 9.87953C3.94192 9.53241 3.87508 8.90278 4.22221 8.47322C4.56933 8.04366 5.19896 7.97682 5.62852 8.32395L7.15589 9.55819L10.2705 6.23598C10.6482 5.83307 11.281 5.81265 11.6839 6.19038Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Безопасность на<br>первом месте</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; width:100%; margin-top:15px;">Безопасность средств и данных пользователей — наш главный приоритет. Мы используем самые современные методы защиты, чтобы обеспечить безопасность каждой транзакции.</p>
        </div>
        <div class='PossibleMobile'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14V6ZM6 8C6.55228 8 7 8.44772 7 9V14C7 14.5523 6.55229 15 6 15C5.44772 15 5 14.5523 5 14V9C5 8.44772 5.44772 8 6 8ZM14 10C14.5523 10 15 10.4477 15 11V14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14V11C13 10.4477 13.4477 10 14 10Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Аналитика на<br>кончиках пальцев</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; margin-top:15px;">Наши аналитические инструменты просты в использовании и подходят как для новичков, так и для профессионалов. Визуализация рыночных трендов, автоматизированные отчеты и прогнозы помогают пользователям принимать обоснованные решения.</p>
        </div>
        <div class='PossibleMobile'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C7.23885 0 4.73748 1.12038 2.92893 2.92893C1.12038 4.73748 0 7.23885 0 10C0 12.7611 1.12038 15.2625 2.92893 17.0711C4.73748 18.8796 7.23885 20 10 20C12.7611 20 15.2625 18.8796 17.0711 17.0711C18.8796 15.2625 20 12.7611 20 10C20 7.23885 18.8796 4.73748 17.0711 2.92893C15.2625 1.12038 12.7611 0 10 0ZM10 5C10.5523 5 11 5.44772 11 6V9.58579L12.7071 11.2929C13.0976 11.6834 13.0976 12.3166 12.7071 12.7071C12.3166 13.0976 11.6834 13.0976 11.2929 12.7071L9.29289 10.7071C9.10536 10.5196 9 10.2652 9 10V6C9 5.44772 9.44771 5 10 5Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Ощути пульс рынка<br>
            в реальном времени</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; width:100%; margin-top:15px;">Платформа CryptoPulse предоставляет пользователям самые актуальные данные с минимальными задержками. Мгновенные оповещения, кастомизированные под ваши предпочтения, помогают быстро реагировать на волатильность рынка.</p>
        </div>
        <div class='PossibleMobile'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM11.3449 5.46591C10.7947 4.35104 9.20494 4.35104 8.65472 5.46591L7.8663 7.06343L6.10332 7.31961C4.87299 7.49839 4.38173 9.01035 5.272 9.87815L6.5477 11.1216L6.24655 12.8775C6.03639 14.1029 7.32253 15.0373 8.42297 14.4588L9.99982 13.6298L11.5767 14.4588C12.6771 15.0373 13.9633 14.1029 13.7531 12.8775L13.4519 11.1216L14.7276 9.87815C15.6179 9.01035 15.1267 7.49839 13.8963 7.31961L12.1334 7.06343L11.3449 5.46591Z" fill="black"/>
          </svg>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 24px;">Интуитивный<br>интерфейс</p>
          <p style="font-family: 'Proxima Nova Regular'; font-weight: 400; font-size: 16px; color: #484848; line-height:19px; width:100%; margin-top:15px;">Наша платформа создана для удобства и эффективности. Простота использования сочетается с мощными функциями, что позволяет сосредоточиться на торговле, не теряя времени на изучение интерфейса.</p>
        </div>
        <a href='/register' style='color:white;'><div class='registerButtonMobile'>
            Зарегистрироваться
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" style='margin-left:10px;'>
            <path d="M0.868089 15.2175C0.477564 15.608 0.477564 16.2411 0.868089 16.6317C1.25861 17.0222 1.89178 17.0222 2.2823 16.6317L0.868089 15.2175ZM17.4244 1.07532C17.4244 0.523033 16.9767 0.0753181 16.4244 0.0753179L7.42444 0.0753187C6.87215 0.0753183 6.42444 0.523034 6.42444 1.07532C6.42444 1.6276 6.87215 2.07532 7.42444 2.07532L15.4244 2.07532L15.4244 10.0753C15.4244 10.6276 15.8722 11.0753 16.4244 11.0753C16.9767 11.0753 17.4244 10.6276 17.4244 10.0753L17.4244 1.07532ZM2.2823 16.6317L17.1315 1.78243L15.7173 0.368212L0.868089 15.2175L2.2823 16.6317Z" fill="white"/>
            </svg>
          </div></a>
      </div>
    </div>
    <div class='thirdBlock' id='thirdBlock'>
      <div class='headThirdBlock'>
        <div style='position: relative;'>
          <p class='thirdBlockHeadFirstText'>Часто задаваемые вопросы</p>
          <p class='thirdBlockHeadSecondText'>FAQ</p>
        </div>
        
      </div>
      <div class='mostQuestionsBlock'>
        <details>
          <summary>Как зарегистрироваться на платформе CryptoPulse?</summary>
          <p>Для регистрации на платформе необходимо нажать на кнопку "Регистрация" на главной странице и следовать инструкциям. Вам потребуется указать электронную почту, создать пароль и подтвердить учетную запись.</p>
        </details>
        <hr>
        <details>
          <summary>Какие криптовалютные пары поддерживаются для торговли опционами?</summary>
          <p>На CryptoPulse можно торговать парами криптовалют и USD, делая прогнозы на движение цен (вверх или вниз) в течение определенного времени.</p>
        </details>
        <hr>
        <details>
          <summary>Как использовать промокод для получения бонуса?</summary>
          <p>Промокод можно ввести при пополнении счета. После успешного ввода вы увидите уведомление о том, что промокод активирован, и бонус к пополнению будет автоматически добавлен на ваш счет.</p>
        </details>
        <hr>
        <details>
          <summary>Как я могу пополнить свой счет?</summary>
          <p>Для пополнения счета перейдите в раздел "Пополнение", выберите удобный способ и следуйте инструкциям. Поддерживаются различные методы оплаты, включая криптовалюты и банковские карты.</p>
        </details>
        <hr>
        <details>
          <summary>Как вывести средства с платформы?</summary>
          <p>Для вывода средств необходимо перейти в раздел "Вывод", выбрать предпочтительный способ и ввести данные для вывода. Средства будут переведены после успешной обработки запроса.</p>
        </details>
        <hr>
        <details>
          <summary>Как проходит верификация аккаунта?</summary>
          <p>Для верификации аккаунта необходимо предоставить необходимые документы в личном кабинете. О необходимости пройти верификацию вы получите уведомление.</p>
        </details>
        <hr>
        <details>
          <summary>Какие уведомления я буду получать?</summary>
          <p>Вы будете получать уведомления о пополнении счета, успешном или неудачном выводе средств, а также текстовые уведомления и напоминания о необходимости верификации.</p>
        </details>
        <hr>
      </div>
      <div class='feelPulse'>
        <img class='feelPulseImg' src='../assets/feelPulse.svg'/>
        <img class='feelPulseImgMobile' src='../assets/feelPulseMobile.svg'/>
      </div>
    </div>
    <div class='footer'>
      <div class='logoFooterBlock'>
        <a href='#header'><img src='../assets/cryptoPulseLogo.svg'/></a>
        <div class='footerText'>
          <p><a href='#secondBlock'>О компании</a></p>
          <p style='margin-bottom: 20px;'><a href='#thirdBlock'>FAQ</a></p>
        </div>
      </div>
      <p>Все права защищены • 2024</p>
    </div>

  </div>
  
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  data() {
    return {
      headBar: false
    }
  },
  methods: {
    showHeadBar() {
      let el = document.querySelector('.headerMobile')
      
      if (!this.headBar) {
        this.headBar = true
        el.style.borderRadius = "10px 10px 0px 0px"
      }
      else {
        this.headBar = false
        el.style.borderRadius = "10px"
      }
    }
  },
  components: {
    
  }
}
</script>
<style scoped>
.header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: white;
  color: black;
  width: 65%;
  height: 68px;
  padding:7px 30px 7px 30px;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  margin-top:20px;
  font-size:18px;
}
.headerMobile {
  display: none;
  background-color: white;
  color: black;
  width: 90%;
  height: 68px;
  padding:7px 30px 7px 30px;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  margin-top:20px;
  font-size:18px;
}
.headMenuMobile {
  position: absolute;
  background-color: white;
  color:black;
  width:90%;
  padding:7px 30px 7px 30px;
  left: 5%;
  display: none;
  flex-direction: column;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mainHead {
  display: flex;
  align-items: center;
  gap: 30px;
}
.authorization {
  display: flex;
  align-items: center;
  justify-self: right;
  gap:30px;
  justify-content: center;
}
.abousUs {
  margin-left:20px;
}
.logoName {
  font-family: 'Proxima Nova Bold';
  font-weight: 700;
  font-size: 20px;
  margin-left:10px;
}
.signUp {
  background-color: #000000;
  border-radius: 7px;
  padding: 7px 32px 7px 32px;
  color: white;
}
.firstBlock {
  height: 75vh;
  background-image: url('../assets/firstBlock.png');
  background-size: 100%;
  background-position: 0vh 10vh;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column;
}
.firstBlockBottom {
  position: absolute;
  bottom: 23%;
  left: 50%;
  transform: translateX(-50%);
}
.firstBlockText {
  margin-top:15vh;
  line-height: 55px;
}
.firstBlockText p:nth-child(1) {
  font-weight: 700; font-size:56px;
}
.firstBlockText p:nth-child(2) {
  color:#FFFFFFB2; font-size: 24px; font-weight: 400;
}
.startTradeButton {
  background-color: #ffffff;
  color:black;
  padding: 7px 16px 7px 16px;
  width: 268px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-family: 'Proxima Nova Bold';
  margin: 0 auto;
  margin-bottom: 3vh;
}
.secondBlock {
  background-color: white;
  border-radius: 30px 30px 0 0;
  height: 100vh;
  width: 100%;
  margin-top:-20px;
  display: flex;
  align-items: center;
}
.secondBlockMobile {
  background-color: white;
  border-radius: 30px 30px 0 0;
  height: max-content;
  width: 100%;
  margin-top:-20px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding:5%;
  padding-bottom: 20%;
}
.possibles {
  display: flex;
  flex-direction: column;
  color: #000000;
  text-align: left;
  margin-top: 20px;
  gap: 50px;
}
.mobileBlock {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: black;
  padding: 5% 10% 10% 10%;
  margin: 0 auto;
  height:100%;
}
.firstPossible { 
  grid-area: 1 / 1 / 2 / 2; 
  text-align: right;
}
.secondPossible { 
  grid-area: 2 / 1 / 3 / 2; 
  text-align: right;
}
.thirdPossible { 
  margin: 0 auto;
  width:100%;
  height:100%;
  grid-area: 1 / 2 / 2 / 3; 
  position: relative;
}
.fourthPossible { 
  grid-area: 1 / 3 / 2 / 4; 
  text-align: left;
}
.fifthPossible { 
  grid-area: 2 / 3 / 3 / 4; 
  text-align: left;
}
.thirdBlock {
  color:white;
  background-color: black;
  border-radius: 30px 30px 0 0;
  width: 100%;
  margin-top:-40px;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/thirdBlock.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 90vh;
}
.thirdBlockHeadFirstText {
  font-size: 56px;
  font-weight: 700;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}
.thirdBlockHeadSecondText {
  color: #FFFFFF17;
  font-size: 266px;
  font-weight: 700;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
}
.footer {
  color:#000000;
  background-color: white;
  border-radius: 30px 30px 0 0;
  width: 100%;
  margin-top:-40px;
  padding: 20px;;
}
.feelPulse {
  margin-top:10%;
  margin-bottom:12%;
  user-select: none;
}
.feelPulseImgMobile {
  display: none;
}
.headThirdBlock {
  height: 40vh;
}
.registerButton {
  background-color:#000000;
  padding: 16px 32px 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:7px;
  color:white;
  position: absolute;
  bottom:-50%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.registerButtonMobile {
  background-color:#000000;
  padding: 16px 32px 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:7px;
  color:white;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  width: 70%;
}
.mostQuestionsBlock {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  font-size:22px;
  color: #FFFFFF;
  z-index:500;
}
.mostQuestionsBlock *{
  margin-bottom:10px;
}

.mostQuestionsBlock hr {
  border-width: 1px;
  border-color: #FFFFFF40;
}
.mostQuestionsBlock summary {
  cursor:pointer;
  user-select: none;
  position: relative;
  padding-right: 20px;
}
.mostQuestionsBlock details p {
  color: #FFFFFF80;
  font-size: 18px;
}
summary::after {
    content: "+";
    color: #fff;
    float: right;
    font-size: 1.5em;
    font-family: 'Proxima Nova Light';
    font-weight: 100;
    padding: 0;
    text-align: center;
    width: 20px;
    transition: 0.2s;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    right: 0;
}
details[open] > summary::after {
  transform: translateY(-50%) rotate(45deg);
  transition: 0.2s;
}
summary::marker {
    content: "";
}
.feelPulseImg {
  width:100%;
}
@media(max-width:968px) {
  .feelPulseImg {
    display: none;
  }
  .feelPulse {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .feelPulseImgMobile {
    display: block;
    margin: 0 auto;
    margin-bottom: 5vh;
  }
  .header {
    display: none;
  }
  .headerMobile {
    display: grid;
    border-radius: 10px;
    border: 1px solid white;
  }
  .headMenuMobile {
    display: flex;
    font-size: 18px;
    gap:10px;
    border-radius: 0px 0px 8px 8px;
  }
  .authorization {
    flex-direction: column;
    gap:10px;
  }
  .signIn {
    border: 1px solid #000000;
    width:100%;
    padding:7px;
    border-radius: 7px;
    font-weight: 700;
    font-size: 16px;
  }
  .signUp {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .mainHead {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .firstBlock {
    height: 80vh;
    background-position: 0vh 20vh;
    background-size:250%;
  }
  .firstBlockText p:nth-child(1) {
    font-weight: 700; font-size:32px;
    line-height: 35px;
    width:87%;
    margin: 0 auto;
  }
  .firstBlockText p:nth-child(2) {
    font-size:20px;
    line-height: 30px;
    width:70%;
    margin: 0 auto;
    margin-top: 15px;
    color: #FFFFFFB2;
  }
  .firstBlockBottom {
    bottom:20%;
  }
  .startTradeButton {
    margin-bottom: 3vh;
  }
  .secondBlock {
    display: none;
  }
  .secondBlockMobile {
    display: flex;
  }
  .headThirdBlock {
    height: 25vh;
  }
  .thirdBlockHeadFirstText {
    font-size: 30px;
    width: 80%;
    top: 10vh;
  }
  .thirdBlock {
    background-size: 150%;
    background-position: 0vw 110vh;
  }
  .thirdBlockHeadSecondText {
    font-size: 155px;
    top:3vh;
  }
  .mostQuestionsBlock {
    width:80%;
  }
  .mostQuestionsBlock summary {
    font-size: 18px;
  }
  .mostQuestionsBlock details p {
    font-size: 16px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
