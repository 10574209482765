import Highcharts from "highcharts";
import StockModule from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";

import { createApp } from 'vue'
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import App from './App.vue'
import router from './router'
import store from './store'
StockModule(Highcharts);
createApp(App).use(store).use(router).use(HighchartsVue).use(Toast, {pauseOnFocusLoss: false}).mount('#app')
